<template>
  <div class="page-order-index">
    <back :back="back" />
    <page-title :title="title">
      <template #description>
        <div :class="$n.orderStatus(order.status).color">{{ $n.orderStatus(order.status).title }}</div>
      </template>
    </page-title>
    <n-loader :loading="$var('load')" />

    <div class="container">
      <order-info :order="order" :seller="order.seller" />

      <order-products :items="products" :can-review="canReview" />

      <div class="buttons">
        <template v-if="canReview">
          <n-link v-if="order.sellerType !== 'store' || !order.seller.isReviewed" :to="{name: 'reviews.create', query: { [order.sellerType]: order.sellerId, },}">
            <n-button color="primary" wide>Оставить отзыв о продавце</n-button>
          </n-link>
        </template>
        <template v-if="canEdit">
          <n-button v-if="order.status === 'formed'" color="primary" wide @click="status('approved')">Принять на исполнение</n-button>
          <n-button v-if="order.status === 'approved'" color="primary" wide @click="status('sold')">Продажа совершена</n-button>
          <n-button wide @click="status('canceled')">Отменить заказ</n-button>
        </template>
        <template v-if="canUserEdit">
          <n-button wide @click="$var('cancel', true)">Отменить заказ</n-button>
        </template>
      </div>
    </div>

    <n-modal v-if="$var('cancel')" :loading="$var('loadCancel')" @close="$var('cancel', false)">
      <n-form @submit="cancelSubmit">
        <n-items>
          <n-select title="Причина отказа" :data="causes" :value.sync="cause" />
          <n-button type="submit">Отменить заказ</n-button>
        </n-items>
      </n-form>
    </n-modal>
  </div>
</template>

<script>
import img from 'assets/temporarily/slide1.jpeg'
import OrderInfo from '../components/OrderInfo'
import OrderProducts from '../components/OrderProducts'

export default {
  name: 'PageOrderIndex',
  components: { OrderProducts, OrderInfo, },
  data() {
    return {
      img,
      order: {},
      products: [],
      causes: [
        'Передумал',
        'Нет подтверждения заказа',
        'Продавец попросил отменить',
        'Не удалось связаться с продавцом',
      ],
      cause: '',
    }
  },
  computed: {
    id() {
      return this.$route.params.id
    },
    title() {
      return 'Заказ №' + (this.order.id || '')
    },
    canReview() {
      if (!this.order.id) {
        return false
      }
      return [ 'sold', 'canceled', ].includes(this.order.status) && $app.auth.user().id === this.order.userId
    },
    canEdit() {
      return this.isSell && [ 'formed', 'approved', ].includes(this.order.status)
    },
    canUserEdit() {
      return !this.isSell && [ 'formed', ].includes(this.order.status)
    },
    isSell() {
      if (this.order.sellerType === 'store') {
        return $app.auth.user().hasStore && $app.auth.user().store.id === this.order.sellerId
      }
      return $app.auth.user().id === this.order.sellerId
    },
    back() {
      return this.isSell ? { name: 'orders.sales', } : { name: 'orders.list', }
    },
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      this.$var('load', true)
      const apis = [
        $api.app.orders.get(this.id).with('seller', (q) => q.with('isReviewed')),
        $api.app.orders.products(this.id).with('isReviewed'),
      ]
      Promise.all(apis).then((response) => {
        this.order = response[0].data.content
        this.products = response[1].data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
    status(status) {
      this.$var('load', true)
      $api.app.orders.status(this.id, { status, }).with('seller', (q) => q.with('isReviewed')).then((response) => {
        this.order = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
    cancelSubmit() {
      this.$var('loadCancel', true)
      const data = {
        status: 'canceled',
        cause: this.cause,
      }
      $api.app.orders.status(this.id, data).with('seller', (q) => q.with('isReviewed')).then((response) => {
        this.order = response.data.content
        this.$var('cancel', false)
        this.cause = ''
      }).finally(() => {
        this.$var('loadCancel', false)
      })
    },
  },
}
</script>

<style scoped lang="scss">
.page-order-index {
  &::v-deep {
    .page-title {
      .success { color: var(--success); }
      .danger { color: var(--danger); }
    }
  }

  .buttons {
    margin-top: 25px;
    &>* {
      margin-top: 15px;
    }
  }

  .send-review{
    display: flex;
    height: 40px;
    align-items: center;
    justify-content: center;
    background: #FFF;
    border: 1px solid #2A82D3;
    box-sizing: border-box;
    border-radius: 100px;
    margin-top: 32px;
    font-weight: 500;
    font-size: 12px;
    color: #2A82D3;
  }

  .total-sum{
    font-size: 14px;
    color: #BABABA;
    text-align: right;

    span{
      font-weight: bold;
      font-size: 16px;
      color: #404040;
      margin-left: 10px;
    }
  }
}
</style>
